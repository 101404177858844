/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, meta }) {
  const { site, seoImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        seoImage: file(relativePath: {eq: "banner-1-0.jpg"}) {
          childImageSharp {
            fixed(height: 400) {
              src
              width
              height
            }
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata.description
  const image={
    url: seoImage.childImageSharp.fixed.src,
    width: seoImage.childImageSharp.fixed.width,
    height: seoImage.childImageSharp.fixed.height
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keyword`,
          content: "人之初,人之初典藏,人之初油飯,油飯,人之初典藏油飯,經典油飯,經典彌月油飯,雞腿禮盒,素油飯,素油飯禮盒,謝籃,素食謝籃",
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? (image.url.startsWith('/')? site.siteMetadata.siteUrl + image.url : image.url) : undefined ,
        },
        {
          property: `og:image:width`,
          content: image ? image.width : undefined ,
        },
        {
          property: `og:image:height`,
          content: image ? image.height : undefined ,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl 
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `zh-tw`,
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
