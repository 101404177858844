/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import SocialMenu from "./social-menu"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div >
        <main>{children}</main>
        <footer id="footer">
          <div className="icon fb"/>
          <div className="icon line"/>
          <div className="infos">
            <ul>
              <li>門市地址｜<a href="https://goo.gl/maps/RCMYcJX27DYm4bcc8" target="_blank" rel="noreferrer">新北市林口區文化三路二段238號</a></li>
              <li>服務時間｜09:00AM~06:00PM</li>
              <li>服務專線｜<a href="tel:02-2601-9322" target="_blank" rel="noreferrer">02-2601-9322</a></li>
            </ul>
          </div>
        </footer>
      </div>
      <SocialMenu/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
