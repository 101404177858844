import { Link , animateScroll as scroll } from "react-scroll";
import React, {useEffect, useState} from "react"
import logo from "../images/logo.png"
import styled from "styled-components"

const Header = styled.header`

  .container {
    .main-icon {
      display: block;
      height: 43px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 15.5px;
      cursor: pointer;
    }

    .navs {
      padding-top: 10.5px;
      padding-bottom: 15px;
      
      background-color: #f0f0f0;
      display: flex;
      justify-content: space-evenly;

      .nav {
        font-size: 15px;
        a {
          cursor: pointer;
          color: #505050;
        }

        @media (hover: hover) {
          a:hover {
            color: #ef9544;
          }
        }
        .active {
          border-bottom: 2px solid #ff5501;
        }
      }
    }
  }
  @media screen and (min-width: 767px) {
    background-color: #ffffffd0;
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;

    .container {
      margin: 0 auto;
      max-width: 1000px;
      display: flex;
      .main-icon {
        margin: 29px auto 18px 28px;
      }

      .navs {
        display: flex;
        background-color: #f0f0f000;
        width: 400px;
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 20px;
        height: 17px;
        align-self: flex-end;
      }
    }
  }
`

function getWindowDimensions() {
  if (typeof window === `undefined`) {
    return {}
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default () => {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const offset = windowDimensions.width >= 767 ? -90 : 0
  return (<Header>

    <div className="container">
      <img className="main-icon" to="top" src={logo} alt='人之初logo' onClick={()=>{scroll.scrollToTop()}}/>

      <ul className="navs">
        <li className="nav"><MyLink to="customer-share" title="口碑分享" offset={offset}/></li>
        <li className="nav"><MyLink to="price-menu" title="商品介紹" offset={offset}/></li>
        <li className="nav"><MyLink to="discount-shipping" title="優惠/運費" offset={offset}/></li>
        <li className="nav"><MyLink to="try-eat" title="預約試吃" offset={offset}/></li>

      </ul>

    </div>
   
  </Header>)
}


const MyLink = ({to, title, offset}) => {
  return <Link to={to} smooth={true} activeClass="active" spy={true} offset={offset?offset:0}
duration={500}>{title}</Link>
}
