import React, { useState } from "react"
import "./floating-menu.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const FloatingMenu = ({children, icon, color}) => {

    const [show, setShow] = useState(false)
    const toogle = () => {
        setShow(!show) 
    }
    return <div className="floating-menu-container">
        <div className={`extern-menu-container ${show? 'show':''}`}>
            {children}
        </div>
        <FloatingMenuItem icon={show?'clear': icon} color={color} action={toogle}/>
    </div>
}

const FloatingMenuItem = ({ label, icon, color, action, children, actionTarget }) => {
  var labelDiv
  if (label) {
    labelDiv = <label>{label}</label>
  }
  console.log(icon)
  var iconDiv = <div className="floating-item-icon" style={{ background: color }} onClick={typeof action === 'function' ? action : undefined}>
    { children ? children : <span className="material-icons">{icon}</span> }
  </div>
  if (typeof action === 'string') {
    // eslint-disable-next-line react/jsx-no-target-blank
    iconDiv = <OutboundLink href={action} target={actionTarget} eventLabel={`${label || action} floating menu`}>
      {iconDiv}
    </OutboundLink>
  }
  return (
    <div className="floating-item">
      {labelDiv}
      {iconDiv}
    </div>
  )
}

export {
    FloatingMenu,
    FloatingMenuItem
}